* {
    box-sizing: border-box;
}

.led-green {
    width: 12px;
    height: 12px;
    background-color: #a1ee02;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #8cff07 0 0px 9px;
    margin: 9px;
}

.led-red {
    width: 12px;
    height: 12px;
    background-color: #ee0202;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #ff0707 0 0px 9px;
    margin: 9px;
}

.led-yellow {
    width: 12px;
    height: 12px;
    background-color: #eee702;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbff07 0 0px 9px;
    margin: 9px;
}

.led-gray {
    width: 12px;
    height: 12px;
    background-color: #e7e7e7;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbfced 0 0px 9px;
    margin: 9px;
}

.led-orange {
    width: 12px;
    height: 12px;
    background-color: #ffa500;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 0px 1px, inset #304701 0 -1px 3px, #fbfced 0 0px 9px;
    margin: 9px;
}

.custom-data-table {
    position: relative !important;
    width: 100%;
    overflow-x: unset !important;
    min-height: 300px !important;
    /* max-height: 100vh; */
}

/* Loading starts */
.loading-container {
    background: #ffffffc4;
    position: absolute;
    /* height: 100vh; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
}

.loading-container.modalLoading {
    height: 100%;
}

.moduleLoader {
    z-index: 100;
}

.loading {
    height: 84px;
    width: 84px;
    border-radius: 50%;
}

.loading {
    border: 2px solid transparent;
    border-color: transparent #000000 transparent #000000;
    -moz-animation: rotate-loading 1.5s linear 0s infinite normal;
    -moz-transform-origin: 50% 50%;
    -o-animation: rotate-loading 1.5s linear 0s infinite normal;
    -o-transform-origin: 50% 50%;
    -webkit-animation: rotate-loading 1.5s linear 0s infinite normal;
    -webkit-transform-origin: 50% 50%;
    animation: rotate-loading 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
}

.loading-container:hover .loading {
    border-color: transparent #001dad transparent #001dad;
}

.loading-container:hover .loading,
.loading-container .loading {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

#loading-text {
    -moz-animation: loading-text-opacity 2s linear 0s infinite normal;
    -o-animation: loading-text-opacity 2s linear 0s infinite normal;
    -webkit-animation: loading-text-opacity 2s linear 0s infinite normal;
    animation: loading-text-opacity 2s linear 0s infinite normal;
    color: #000000;
    font-family: "Helvetica Neue, " Helvetica ", " "arial";
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    margin-left: 84px;
    margin-top: 12px;
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
    width: 84px;
}

@keyframes loading-text-opacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes loading-text-opacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes loading-text-opacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes loading-text-opacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

/* Loading ends */

label {
    margin-bottom: 5px;
    color: #9c9c9c;
}

input:focus {
    box-shadow: none !important;
}

.rdt_TableBody div:first-child {
    white-space: nowrap;
    overflow: unset !important;
    text-overflow: ellipsis;
}

.profile-img,
.rdt_TableBody div:first-child .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 6px 0;
    border: 1px solid #cccccc;
    overflow: hidden !important;
}

.square-img,
.rdt_TableBody div:first-child .square-img {
    height: 75px;
    width: 75px;
    border: 1px solid #cccccc;
    margin: 6px auto !important;
    text-align: center;
    overflow: hidden !important;
}

.square-img img {
    height: inherit;
    width: auto;
    object-fit: contain;
    margin: 0 auto !important;
}

.profile-img img {
    width: inherit;
    height: inherit;
    object-fit: contain;
}

.dropdown-menu li {
    color: #070606;
    font-size: 14px;
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #cccccc;
    transition: 0.3s ease;
}

.dropdown-menu li:hover {
    background: #5169dc;
    color: white;
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu li a {
    display: block;
}

.dropdown-menu {
    background: #fffefe;
    padding: 0px;
}

.dropdown-menu li i {
    margin-right: 5px;
}

.deletedBtn {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 4;
}

.deletedBtn label {
    margin-bottom: 0;
}

.deletedBtn input {
    margin-right: 10px;
}

.profileDetailRow .input-group * {
    background-color: transparent !important;
}

.profileDetailRow .input-group .input-group-text {
    display: block !important;
    border-radius: 4px 0 0 4px;
}

.profileDetailRow .playerDetailProfile {
    height: 240px;
    width: auto;
}

.the-legend {
    border-style: none;
    border-width: 0;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    width: auto;
    padding: 0 10px;
    border: 1px solid #e0e0e0;
}

.the-fieldset {
    border: 1px solid #e0e0e0;
    padding: 10px;
}

.gameConfigBlock {
    padding: 14px;
    border-radius: 4px;
    box-shadow: 0 2px 15px -4px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
}

.gameConfigBlock h4 {
    font-size: 16px;
    font-weight: 600;
}

fieldset {
    position: relative;
    padding: 25px 15px 15px 15px;
    border: 1px solid #cccccc;
}

fieldset legend {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 14px;
    background-color: #ffffff;
    width: auto;
    padding: 0 10px;
    color: #a1a1a1;
}

.restaurant-images .imageParentDiv {
    height: 150px !important;
}

.restaurant-images .imageParentDiv img {
    object-fit: fill !important;
}

.imageParentDiv {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    text-align: center;
    margin: 0 auto;
}

.imageParentDiv img {
    cursor: pointer;
    width: inherit;
    height: inherit;
    object-fit: cover;
}

.logo .imageParentDiv img {
    padding: 4px;
}

.cstm-obj-fit .imageParentDiv img {

    object-fit: scale-down;
}


.imageLoaderParent {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffffbd;
}

.imageLoader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
}

.tableView {
    position: relative;
}

.paginationSearchInput {
    width: auto;
    position: absolute;
    right: 132px;
    top: -2px;
    height: 36px;
    z-index: 4;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
}

.paginationSearchInput input {
    width: auto;
    padding: 0;
    box-shadow: none !important;
    border: none;
}

.paginationSearchInput .input-group-prepend {
    width: 40px;
    border-right: 1px solid #e6e6e6;
}

.paginationSearchInput .input-group-text {
    position: absolute;
    height: 100%;
    z-index: 4;
    padding: 0px;
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.searchOnlyBlock .paginationSearchInput {
    position: relative;
    right: unset;
}

.actionBtn {
    padding: 6px 10px;
    font-size: 14px;
}

.actionBtn::after {
    display: none;
}

.customButton {
    margin-bottom: 10px;
    margin-left: auto;
    display: flex;
}

.customButton i {
    font-size: 18px;
    margin-right: 4px;
}

.modal-header {
    padding: 10px 20px;
}

.modal-header .modal-title {
    font-size: 18px;
    font-weight: 600;
}

.select-style {
    width: 100%;
    height: 35px;
    padding: 5px;
    border: 1px solid #ccc;
    outline: none;
}

.listBtn {
    padding: 10px;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
    line-height: 12px;
    border-radius: 4px;
    background-color: #556ee6;
}

.actionBtn {
    padding: 10px;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
    line-height: 22px;
    border-radius: 4px;
    background-color: #556ee6;
}

.main-content {
    position: relative;
}

.listBtn {
    padding: 10px;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
    line-height: 12px;
    border-radius: 4px;
    background-color: #556ee6;
}

.actionBtn {
    padding: 10px;
    border: none !important;
    outline: none !important;
    color: #ffffff !important;
    line-height: 22px;
    border-radius: 4px;
    background-color: #556ee6;
}

.main-content {
    position: relative;
}

.createButton {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1;
}

.right-modal-dialog {
    position: fixed;
    margin: auto;
    width: 60%;
    height: 100%;
    right: 0;
}

.right-modal-content {
    height: 100%;
}

.right-modal-content .modal-body {
    overflow-y: scroll;
    max-height: 95vh;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
    z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
    z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
    z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
    z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
    z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
    z-index: 1000;
}

.aggregate-block {
    position: relative;
    border: 1px solid #ddd;
}

.aggregate-block .remove-aggregate-icon {
    width: 10px;
    height: 24px;
    position: absolute;
    right: -9px;
    top: -9px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.pac-container {
    z-index: 1100 !important;
}

.choose-map-address {
    position: absolute;
    z-index: 10000;
    right: 0;
    top: 34px;
}

.remove-time-row {
    position: absolute;
    top: 11px;
    right: 3px;
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: red;
    border-radius: 50px;
    font-size: 14px;
    line-height: 26px;
    font-weight: bold;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
}

.time-list {
    overflow-y: auto;
    max-height: 245px;
    overflow-x: hidden;
    padding-right: 10px;
}

.nav-tabs li.active a {
    color: #000 !important;
    background-color: #f2f5f7 !important;
    border-color: #0041ff !important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    vertical-align: middle;
    border-radius: 0.25rem 0.25rem 0 0;
    border-top: 2px solid;
}

.nav-tabs li a {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

::-webkit-scrollbar-thumb {
    background-color: #646363;
}

#sidebar-menu {
    height: 100%;
}

.vertical-menu {
    overflow-y: auto;
}

.vertical-menu::-webkit-scrollbar {
    width: 4px;
}

.vertical-menu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.vertical-menu::-webkit-scrollbar-thumb {
    background: #d7d7d7;
}

.vertical-collpsed .vertical-menu {
    overflow-y: unset;
}

.sub-menu {
    list-style: none;
}

.mm-active .active {
    color: #556ee6 !important;
    background-color: #f5f5f5;
}

#sidebar-menu ul li ul.sub-menu li a {
    padding: 10px 24px 4px 40px;
}

#sidebar-menu ul li ul.sub-menu li ul.sub-menu li a {
    padding: 10px 24px 4px 60px;
}

#sidebar-menu ul li a i.bi.bi-chevron-down {
    min-width: unset;
}

.div-cardShadow {
    box-shadow: 10px 10px 8px 10px rgba(black, 0.175);
}

.customize-option {
    top: -10px !important;
    right: -10px !important;
}

.badge-available,
.badge-unavailable {
    height: 26px;
    width: 26px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    border-radius: 4px;
    color: #ffffff;
    cursor: default;
    font-size: 12px;
}

.badge-available {
    background-color: #0bd800;
}

.badge-unavailable {
    background-color: #a0a0a0;
}

.list-img {
    border: 1px solid #e5e5e5;
    height: 40px;
    width: 40px;
    margin: 6px 0;
}

.list-img img {
    height: inherit;
    width: inherit;
    object-fit: contain;
}

.table-list-img {
    border: 1px solid #e5e5e5;
    height: 50px;
    width: 50px;
    margin: 6px 0;
}

.table-list-img img {
    height: inherit;
    width: inherit;
    object-fit: contain;
}

.sync-copy-item {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 155px;
    max-height: 155px;
    border-color: #ccc !important;
}

.sync-copy-item .list-group-item {
    cursor: pointer;
}

.sync-copy-item .list-group-item:last-of-type {
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.sync-copy-arrow-btn {
    cursor: pointer;
    font-size: 25px;
    line-height: 18px;
    border-color: #ccc !important;
}

.copy-sync-select-item {
    background: #8a8888db;
    color: #fff;
}

.model-list-url {
    position: relative;
}

.model-list-url::before {
    position: absolute;
    content: "";
    left: 5px;
    top: 22px;
    bottom: 10px;
    width: 1px;
    background: #9c9c9c;
}

.model-url-checkbox {
    margin-left: 45px;
    position: relative;
}

.model-url-checkbox::before {
    position: absolute;
    content: "";
    height: 1px;
    left: -40px;
    background: #9c9c9c;
    width: 30px;
}

.react-confirm-alert-overlay {
    z-index: 1076 !important;
}

.assign-urls-btn {
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 9999;
}

.text-red {
    color: red !important;
}

.bg-red {
    background: #ee0d0d;
    color: #fff;
    border: 1px solid #ee0d0d;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}

.background-red {
    background: #ee0d0d;
}

.info-msg {
    color: #b94f4f !important;
}

.customization-options-images .imageParentDiv {
    border: none;
}

.img-delete-btn {
    background: #000000;
    color: #fff;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    font-size: 15px;
    justify-content: center;
    position: absolute;
    z-index: 1;
    right: 0px;
    top: 0px;
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

.owned-flag {
    position: absolute;
    left: 0;
    top: 2px;
}

.parent-owned-flag {
    position: relative;
    padding-left: 18px;
}

.split-bill {
    height: 28px;
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 2px;
    border-radius: 4px;
    color: #ffffff;
    cursor: default;
    font-size: 13px;
}

.cms-bg .card-body {
    box-shadow: 0px 2px 10px 3px #d2d2e1;
}

/* 
.ck-blurred.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    min-height: 50vh;
}
.custom-column-action{
    overflow: unset !important;
}
.ws-initial{
    white-space: initial !important;
}

/* responsive-css */
@media (max-width: 1350px) {
    .custom-data-table {
        position: relative !important;
        width: 100%;
        overflow-x: auto !important;
        overflow-y: unset !important;
        min-height: 320px !important;
    }
}

@media (max-width: 992px) {
    .vertical-menu {
        display: none;
    }

    body.sidebar-enable .vertical-menu {
        display: block;
    }

    .vertical-collpsed .vertical-menu {
        width: 260px !important;
    }

    .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
        display: flex;
        align-items: center;
    }

    .vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a span {
        display: block;
        padding-left: 25px;
    }
}

@media (max-width: 575px) {
    .paginationSearchInput {
        left: 0;
        right: 0;
        top: 45px;
    }

    .custom-table-header {
        width: 100%;
        padding-top: 75px;
    }

    .searchOnlyBlock .paginationSearchInput {
        position: absolute;
        right: 0;
    }
}

.card-body-scroll {
    overflow: hidden;
    overflow-y: scroll;
}

.ck-editor__editable {
    height: 50vh;
}

.dinning-order-detail {
    padding-right: 14px !important;
    text-align: right !important;
}

.model-header-btn button {
    font-size: 12px;
    font-weight: 500;
    height: 25px;
}

.custom-data-table .rdt_TableHeadRow {
    font-size: 14px !important;
}

.overlay-custom-class-name .react-confirm-alert-body {
    text-align: center;
}

.overlay-custom-class-name .react-confirm-alert-button-group {
    justify-content: center;
}

.account-pages {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cls-time-btn {
    left: auto !important;
    right: auto !important;
    width: 230px;
}

.order-count {
    border-radius: 30%;
    padding: 1px 5px;
    color: white;
    font-size: 10px;
    /* background-color: rgb(245, 115, 115); */
}

.reportDataTable .rdt_TableRow:last-child {
    background-color: #efefef;
    font-size: 14px;
    font-weight: 500;
}

.pdf-downloadbtn {
    position: absolute;
    right: 13.5%;
    top: 7.3%;
    z-index: 1;
}